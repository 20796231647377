import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { Card, CardContent } from '@/components/ui/card';

const TimerDashboard = () => {
  const [totalTimeMinutes, setTotalTimeMinutes] = useState(60);
  const [totalQuestions, setTotalQuestions] = useState(10);
  const [timeLeft, setTimeLeft] = useState(totalTimeMinutes * 60);
  const [questionsLeft, setQuestionsLeft] = useState(totalQuestions);
  const [currentQuestionTime, setCurrentQuestionTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isSetup, setIsSetup] = useState(true);

  const timePerQuestion = Math.floor((totalTimeMinutes * 60) / totalQuestions);
  const averageTimeLeft = questionsLeft > 0 ? Math.floor(timeLeft / questionsLeft) : 0;
  const isAverageTimeBelowThreshold = averageTimeLeft < timePerQuestion;

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (isRunning) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        setCurrentQuestionTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(Math.abs(seconds) / 60);
    const secs = Math.abs(seconds) % 60;
    return `${seconds < 0 ? '-' : ''}${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleMarkQuestion = () => {
    if (questionsLeft > 0) {
      setQuestionsLeft((prev) => prev - 1);
      setCurrentQuestionTime(0);
    }
  };

  const toggleTimer = () => {
    setIsRunning((prev) => !prev);
  };

  const handleSetup = () => {
    setTimeLeft(totalTimeMinutes * 60);
    setQuestionsLeft(totalQuestions);
    setCurrentQuestionTime(0);
    setIsRunning(false);
    setIsSetup(false);
  };

  const currentQuestionTimeLeft = timePerQuestion - currentQuestionTime;
  const isOverspending = currentQuestionTime >= timePerQuestion;
  const questionTimeColor = isOverspending ? 'text-red-500' : 'text-green-500';
  const isTestCompleted = timeLeft === 0 || questionsLeft === 0;

  return (
    <div className="container mx-auto p-4 space-y-8">
      {/* Timer Dashboard */}
      <Card className="max-w-sm mx-auto">
        <CardContent className="p-6 space-y-4">
          {isSetup ? (
            <>
              <h1 className="text-2xl font-bold text-center">Timer Setup</h1>
              <div className="space-y-2">
                <label className="block">
                  Total Time (minutes):
                  <Input
                    type="number"
                    value={totalTimeMinutes}
                    onChange={(e) => setTotalTimeMinutes(Number(e.target.value))}
                    min="1"
                  />
                </label>
                <label className="block">
                  Total Questions:
                  <Input
                    type="number"
                    value={totalQuestions}
                    onChange={(e) => setTotalQuestions(Number(e.target.value))}
                    min="1"
                  />
                </label>
              </div>
              <Button onClick={handleSetup} className="w-full">Start Timer</Button>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-center">Timer Dashboard</h1>
              <div className="space-y-2">
                <p className="text-lg">Total Time Left: <span className="font-bold">{formatTime(timeLeft)}</span></p>
                <p className="text-lg">Questions: <span className="font-bold">{questionsLeft}/{totalQuestions}</span></p>
                <p className="text-lg">
                  Time Left for Current Question:
                  <span className={`font-bold ${questionTimeColor}`}> {formatTime(currentQuestionTimeLeft)}</span>
                </p>
                <p className="text-lg">
                  Avg. Time Left per Question:
                  <span className={`font-bold ${isAverageTimeBelowThreshold ? 'text-yellow-500' : 'text-green-500'}`}>
                    {' '}{formatTime(averageTimeLeft)}
                  </span>
                </p>
                <p className="text-sm">Time per question: {formatTime(timePerQuestion)}</p>
              </div>

              {!isTestCompleted && (
                <div className="flex justify-between">
                  <Button onClick={handleMarkQuestion} disabled={questionsLeft === 0}>
                    Mark Question
                  </Button>
                  <Button onClick={toggleTimer}>
                    {isRunning ? 'Pause' : 'Start'}
                  </Button>
                </div>
              )}

              {timeLeft === 0 && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Time's up!</AlertTitle>
                  <AlertDescription>
                    The total time for the test has elapsed.
                  </AlertDescription>
                </Alert>
              )}

              {questionsLeft === 0 && (
                <Alert>
                  <CheckCircle className="h-4 w-4" />
                  <AlertTitle>All questions answered!</AlertTitle>
                  <AlertDescription>
                    You've completed all the questions.
                  </AlertDescription>
                </Alert>
              )}

              {isTestCompleted ? (
                <Button onClick={() => setIsSetup(true)} className="w-full">Restart Timer</Button>
              ) : (
                <Button onClick={() => setIsSetup(true)} className="w-full">Reset</Button>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Test Information Table */}
      <Card className="mx-auto">
        <CardContent className="p-6">
          <h2 className="text-2xl font-bold mb-4">Standard Test Information</h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b">
                  <th className="text-left p-4 text-sm font-medium text-muted-foreground">Test Name</th>
                  <th className="text-left p-4 text-sm font-medium text-muted-foreground">Total Time</th>
                  <th className="text-left p-4 text-sm font-medium text-muted-foreground">Total Questions</th>
                  <th className="text-left p-4 text-sm font-medium text-muted-foreground">Sections</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">SAT</td>
                  <td className="p-4 align-top">3h 00m</td>
                  <td className="p-4 align-top">156</td>
                  <td className="p-4">
                    <div>Reading & Writing: 98 questions (64 min)</div>
                    <div>Math: 58 questions (80 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">ACT</td>
                  <td className="p-4 align-top">3h 35m</td>
                  <td className="p-4 align-top">216</td>
                  <td className="p-4">
                    <div>English: 75 questions (45 min)</div>
                    <div>Math: 60 questions (60 min)</div>
                    <div>Reading: 40 questions (35 min)</div>
                    <div>Science: 40 questions (35 min)</div>
                    <div>Optional Essay: 1 essay (40 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">GRE</td>
                  <td className="p-4 align-top">3h 45m</td>
                  <td className="p-4 align-top">82</td>
                  <td className="p-4">
                    <div>Analytical Writing: 2 essays (60 min)</div>
                    <div>Verbal Reasoning 1: 20 questions (30 min)</div>
                    <div>Verbal Reasoning 2: 20 questions (30 min)</div>
                    <div>Quantitative Reasoning 1: 20 questions (35 min)</div>
                    <div>Quantitative Reasoning 2: 20 questions (35 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">GMAT</td>
                  <td className="p-4 align-top">3h 7m</td>
                  <td className="p-4 align-top">80</td>
                  <td className="p-4">
                    <div>Analytical Writing: 1 essay (30 min)</div>
                    <div>Integrated Reasoning: 12 questions (30 min)</div>
                    <div>Quantitative: 31 questions (62 min)</div>
                    <div>Verbal: 36 questions (65 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">LSAT</td>
                  <td className="p-4 align-top">2h 55m</td>
                  <td className="p-4 align-top">102</td>
                  <td className="p-4">
                    <div>Logical Reasoning 1: 25 questions (35 min)</div>
                    <div>Logical Reasoning 2: 26 questions (35 min)</div>
                    <div>Analytical Reasoning: 23 questions (35 min)</div>
                    <div>Reading Comprehension: 27 questions (35 min)</div>
                    <div>Writing Sample: 1 essay (35 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">MCAT</td>
                  <td className="p-4 align-top">7h 30m</td>
                  <td className="p-4 align-top">230</td>
                  <td className="p-4">
                    <div>Chemical/Physical Foundations: 59 questions (95 min)</div>
                    <div>Critical Analysis (CARS): 53 questions (90 min)</div>
                    <div>Biological/Biochemical: 59 questions (95 min)</div>
                    <div>Psychological/Social: 59 questions (95 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">CPA</td>
                  <td className="p-4 align-top">16h 0m</td>
                  <td className="p-4 align-top">276</td>
                  <td className="p-4">
                    <div>Auditing (AUD): 72 questions (4h)</div>
                    <div>Business Environment (BEC): 62 questions (4h)</div>
                    <div>Financial Accounting (FAR): 66 questions (4h)</div>
                    <div>Regulation (REG): 76 questions (4h)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">CFA Level I</td>
                  <td className="p-4 align-top">4h 30m</td>
                  <td className="p-4 align-top">180</td>
                  <td className="p-4">
                    <div>Morning Session: 90 questions (135 min)</div>
                    <div>Afternoon Session: 90 questions (135 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">PMP</td>
                  <td className="p-4 align-top">4h 0m</td>
                  <td className="p-4 align-top">180</td>
                  <td className="p-4">
                    <div>Full Exam: 180 questions (240 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">CISSP</td>
                  <td className="p-4 align-top">6h 0m</td>
                  <td className="p-4 align-top">125</td>
                  <td className="p-4">
                    <div>Full Exam: 125 questions (360 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">ITIL Foundation</td>
                  <td className="p-4 align-top">1h 0m</td>
                  <td className="p-4 align-top">40</td>
                  <td className="p-4">
                    <div>Full Exam: 40 questions (60 min)</div>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-4 align-top font-medium">TOEFL iBT</td>
                  <td className="p-4 align-top">3h 0m</td>
                  <td className="p-4 align-top">75</td>
                  <td className="p-4">
                    <div>Reading: 35 questions (54 min)</div>
                    <div>Listening: 34 questions (41 min)</div>
                    <div>Speaking: 4 tasks (17 min)</div>
                    <div>Writing: 2 tasks (50 min)</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardContent>
        </Card>
    </div>
);
};

export default TimerDashboard;
